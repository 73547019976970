.inner{
  display: flex;
  &__search{
    display: flex;
    height: 44px;
    align-items: center;
    position: relative;
    button{
      margin-left: 16px;
      border-radius: 8px;
      border: 1px solid var(--Accent, #1755E7);
      background: var(--Accent, #1755E7);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: var(--White, #FFF);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      height: 44px;
      width: 100px;
      cursor: pointer;
    }
  }
  &__inputBox{
    position: relative;
  }
  &__form{
    margin-right: 24px;
  }
}
.cancelRequest{
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--White, #FFF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 352px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0;
}
