.header{
  text-align: center;
  margin: 0 auto;
  &__title{
    color: var(--Grey-Grey-90, #111827);
    font-family: Inter, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 12px;
    margin-top: 40px;
  }
  &__subtitle{
    color: var(--Grey-Grey-90, #111827);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 40px;
  }
}