.viewBtns {
  display: flex;
}
.viewBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #111827;
  text-align: center;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  cursor: pointer;
  padding: 6px 12px 6px 10px;
  transition: all .3s;

  svg {
    margin-right: 8px;
  }

  &_save {
    background: #0707FA;
    border: 1px solid #0707FA;
    color: #FFFFFF;
    margin-left: 6px;
  }

  &_cancel {
    color: #111827;
    background-color: #fff;
    border: 1px solid #E5E7EB;
    min-width: 121.5px;
  }

  &:hover {
    border-color: #0707FA;
    color: #0707FA;
    svg{
      path{
        stroke: #0707FA;
      }
    }
  }
  &:first-child{
    &:hover {
      border-color: #0707FA;
      color: #0707FA;
      svg{
        path{
          stroke: #0707FA;
          fill: #0707FA;
        }
      }
    }
  }
  &[disabled]{
    &:first-child{
      &:hover {
        border-color: #E5E7EB !important;
        color: #111827 !important;
        svg{
          path{
            stroke: #111827;
            fill: #111827;
          }
        }
      }
    }
    border-color: #E5E7EB !important;
    color: #111827 !important;
    svg{
      path{
        stroke: #111827 !important;
      }
    }
  }
}
@media (max-width: 768px) {
  .viewBtns{
    flex-direction: column-reverse;
    padding: 0 20px;
  }
  .viewBtn{
    & + &{
      margin-top: 12px;
    }
    &__resetMobile{
      border-radius: 8px;
      background: var(--White, #FFF);
      border: 1px solid var(--Gray-300, #FFF);
      color: var(--accent, #0707FA);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      height: 44px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 72px;
    }
    &__saveMobile{
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--White, #FFF);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: var(--Gray-700, #344054);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      height: 44px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
    }
    &__resetFilters{
      border-radius: 8px;
      border: 1px solid var(--Primary-50, #F9F5FF);
      background: var(--Primary-50, #F9F5FF);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: var(--accent, #0707FA);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      height: 44px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
    }
  }
}