.modal{
  position: absolute;
  background: rgba(0, 0, 0, 0.40);
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 32px 0;
  height: 100vh;
  &__inner{
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width: 808px;
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 100%;
    max-height: 810px;
    padding: 24px 10px 24px 38px;
    position: relative;
  }
  &__innerContent{
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    flex-direction: column;
    align-items: center;
    display: flex;
    padding-right: 10px;

    &::-webkit-scrollbar-thumb {
      background-color: #EAECF0; /* Adjust the color of the scrollbar thumb */
      border-radius: 12px; /* Adjust the border radius as needed */
    }
    &::-webkit-scrollbar {
      width: 10px; /* Adjust the width of the scrollbar */
      background-color: transparent; /* Adjust as needed */
      border-radius: 12px;
    }

  }
  &__logo{
    border-radius: 28px;
    border: 8px solid var(--primary-50, #F9F5FF);
    background: #F5F5FF;
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  &__title{
    color: var(--gray-900, #101828);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
  }
}
.closeModal{
  position: absolute;
  right: 1px;
  top: 3px;
  background-color: transparent;
  outline: none;
}