.tableInner {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  padding: 0 12px;
  width: 100%;
  overflow: hidden;
}

.tableTitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #111827;
  margin-left: 2px;
  margin-top: 6px;
  margin-bottom: 4px;

}

.saveGridBtn {
  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 8px;
  margin-top: 6px;
}

.filtersModal {
  background: #FFFFFF;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: 15;
  height: fit-content;
  right: 12px;
  top: -84px;

  &__header {
    padding: 12px 12px 8px 12px;
    border-bottom: 2px solid #F2F2F4;

    h3 {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 8px;
      margin-left: 5px;
    }
  }

  &__defaultView {
    background: #F7F7F7;
    border-radius: 8px;
    padding: 10px 15px;
    height: 40px;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }

  &__item {
    cursor: pointer;
    height: 27px;
    display: flex;
    align-items: center;
    margin: 0 22px 0 18px;

    & + & {
      margin-top: 2px;
    }

    &:hover {
      background: #F7F7F7;
      border-radius: 4px;
      margin: 0 12px 0 8px;
      padding-right: 14px;
      padding-left: 6px;
    }
  }

  &__shownHidden {
    padding: 8px 10px 0 0;
    border-bottom: 1px solid #F2F2F4;

    h4 {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      margin-left: 12px;
      color: #B1B2B6;
      margin-bottom: 0;

    }
  }
}

.sliderScrollBtn {
  background: linear-gradient(270deg, #FFFFFF 60.19%, rgba(255, 255, 255, 0.77) 100%);
  width: 74px;
  height: 36px;
  border: none;
  cursor: pointer;
}

.showFilters {
  width: 32px;
  height: 32px;
  background: #E6E8EA;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.checkbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;

  p {
    margin-bottom: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }

  & + & {
    margin-top: 2px;
  }
}

.sortingButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  span {
    height: 10px;
    width: 10px;
    padding: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      svg path {

        stroke: #111827;

      }
    }
  }
}

.columnHeader {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #6B7280;
}

.hideShowSwitcher {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  border: none;
  background-color: transparent;
  color: #1755E7;
  cursor: pointer;
}

.filtersSwitcherInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.contentInner {
  display: flex;
}

.messageInner {
  background: #FFFFFF;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14);
  border-radius: 8px 0 0 8px;
  margin-left: 12px;
  //max-height: 860px;

  &__header {
    background: #424242;
    border-radius: 8px 0 0 0;
    display: flex;
    padding: 23px 12px 23px 16px;
    justify-content: space-between;
    align-items: center;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: #FFFFFF;
      margin-bottom: 0;
    }

    button {
      height: 16px;
      width: 16px;
      padding: 0;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
  }

  &__properties {
    margin-top: 32px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    padding-bottom: 3px;
    border-bottom: 1px solid #EDEFF1;
    margin-bottom: 37px;
  }

  &__main {
    padding: 21px 16px 26px 16px;
    position: relative;
  }

  &__property {
    background: #FFFFFF;
    border: 1px solid rgba(217, 217, 217, 0.25);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1890FF;
    width: fit-content;
    padding: 3px 8px;
    display: flex;
    cursor: pointer;
    margin-bottom: 4px;
    margin-left: 4px;

    button {
      background-color: transparent;
      border: none;
      color: #CCCCCC;
      margin-left: 12px;
      height: 12px;
      width: 12px;
      padding: 0;
      margin-top: 2px;
      cursor: pointer;
    }
    span , p{
      background: #FFFFFF;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #1890FF;
      width: fit-content;
      display: flex;
      cursor: pointer;
      margin-bottom: 0;
    }
  }

  &__propertiesInner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
  }

  &____message {
    textarea {
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
    }
  }

  &__messageText {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
  }

  &__subject {
    border: none;
    border-bottom: 1px solid #EDEFF1;
    border-radius: 0;
    outline: none;
    height: 26px;
    padding: 0 6px 9px 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
  }

  &__textArea {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
  }

  &__item {
    margin-bottom: 18px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;

    &:before {
      content: 'To';
      width: 20px;
      top: 7px;
      position: absolute;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      z-index: 5;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  &__item_cc {
    &:before {
      content: 'CC';
    }
  }

  &__item_bcc {
    &:before {
      content: 'BCC';
      width: 32px;
    }
  }

  &__item_owner {
    &:before {
      content: 'Owner';
    }
  }

}

.optionName {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-right: 8px;
}

.optionEmail {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.clearFiltersBtn {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 35px 10px 15px;
  transition: all .3s;

  &:hover {
    color: #0707FA;
    background-color: #F9FAFB;
  }
}


.rowsPerPage {
  height: 40px;
  margin: 0;
  border-top: none;
  border-bottom: none;
  font-family: Inter, sans-serif !important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
}

.footerText {
  margin-bottom: 0;
  font-family: Inter, sans-serif !important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px;
}

.selectBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.contactSearchList {
  padding: 0;

  &__inputInner {
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    outline: none;
    display: flex;
    align-items: center;
    padding: 10px 14px;
  }

  label {
    height: 20px;
    width: 20px;

    img {
      object-fit: cover;
    }
  }

  input {
    height: 24px;
    border: none;
    outline: none;
    width: 100%;
    margin-left: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }

  &__listInner {
    background: #FFFFFF;
    border: 1px solid #F2F4F7;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px 8px 0 0;

    ul {
      padding: 0;

      li {
        list-style: none;

        button {
          background-color: transparent;
          border: none;
          width: 100%;
          text-align: start;
          padding: 14px 10px;
          transition: all .3s;
          cursor: pointer;

          &:hover {
            background: #F9FAFB;
          }
        }
      }
    }
  }

  &__title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #6B6B6B;
    margin: 4px 0 0 14px;
  }

  &__labelName {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
  }

  &__labelEmail {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #667085;
    margin-left: 8px;
  }
}

.offerSelectCreateContact {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1890FF;
  margin-left: 8px;
  bottom: 0;
  padding: 10px 14px;
  border-top: 1px solid #D0D5DD;
  width: 98%;
  cursor: pointer;

  span {
    margin-left: 8px;
  }
}

.offerSelectCreateBtn {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 0 0 17px;
  cursor: pointer;

  &_rotated {
    transform: rotate(45deg);
  }
}

//.offerLabelTitle{
//  font-family: 'Roboto', sans-serif;
//  font-style: normal;
//  font-weight: 400;
//  font-size: 14px;
//  line-height: 16px;
//  color: rgba(0, 0, 0, 0.54);
//  position: relative;
//  &:after{
//    content: '';
//    width: 20px;
//    height: 1px;
//    background-color: #EDEFF1;
//    position: absolute;
//    bottom: -17px;
//    left: 0;
//  }
//}
.offerListName {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-right: 8px;
}

.offerListEmail {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}

.offerInputInner {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  display: flex;
  padding: 8px 14px;
}

.offerInput {
  border: none;
  outline: none;
}

.contactItem {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #1890FF;
  display: flex;
  align-items: center;
  border: 1px solid rgba(217, 217, 217, 0.25);
  border-radius: 8px;
  height: 30px;
  background-color: #FFFFFF;
  padding: 7px;
  margin-bottom: 4px;
  justify-content: space-between;
  margin-left: 4px;
}

.offerListInner {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.12));
  position: relative;
  z-index: 999;
  background-color: #fff;
  border-radius: 4px;
  width: 480px;
}

.OfferListItem {
  padding: 10px 14px;
  transition: all .5s;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #F9FAFB !important;
  }
}

.offerContactInput {
  padding-bottom: 4px;
  margin-left: 45px;
}

.OfferListAddContact {
  position: absolute;
  bottom: 0;
}

.offerContactDelete {
  color: #CCCCCC;
  font-size: 14px;
  margin-left: 37px;
  cursor: pointer;
}

.offerInputSecond {
  border: none;
  outline: none;
}

.offerToTitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #6B6B6B;
  margin-left: 14px;
  margin-top: 4px;
  margin-bottom: 0;
}

.openListBtn {
  position: absolute;
  top: 0;
  right: 5px;
}

.clearBtns {
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: #E6E8EA;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-left: 12px;

  &__btns {
    position: absolute;
    width: 132px;
    background: #FFFFFF;
    border: 1px solid #F2F4F7;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    right: 115px;
    z-index: 10;
    margin-top: 33px;
    top: 3px;
  }
}

.surfaceBtn {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background: #F9F5FF;
  border-radius: 8px;
  color: #1890FF;
  width: 71px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
}

.offerBtn {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background: #F9F5FF;
  border-radius: 8px;
  color: #1890FF;
  width: 71px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
}

.surfaceInner {
  margin: 7px 0 7px 35px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: fit-content;
  &::-webkit-scrollbar-thumb {
    background-color: #EAECF0; /* Adjust the color of the scrollbar thumb */
    border-radius: 12px; /* Adjust the border radius as needed */
  }
  &::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
    background-color: transparent; /* Adjust as needed */
    border-radius: 12px;
  }
}

.surfaceList {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 120%;
  color: #131313;
  display: flex;
  align-items: center;
}

.customRow {
  border-bottom: 1px solid #E5E7EB;

  &:nth-of-type(odd) {
    background-color: #F9FAFB;
  }
}

.activityButtons {
  display: flex;
  height: 45px;
  align-items: center;
  border-bottom: 1px solid #F7F8FA;
}

.activityFilter {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #6F7277;
  padding: 4px 8px;
  border: none;
  outline: none;
  background-color: transparent;
  margin-left: 16px;
  cursor: pointer;
}

.activeActivityFilter {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
  background: #1755E7;
  border-radius: 21px;
  border: none;
  outline: none;
  padding: 4px 8px;
  margin-left: 16px;
  cursor: pointer;
}

.dateCreatedActivity {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #161B25;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(195, 205, 217, 0.5);
  border-radius: 8px;
  padding: 4px 16px;
  width: fit-content;
  margin: 9px auto;
}

.activityDescText {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__upper {
    display: flex;
    margin-bottom: 6px;

    p {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #161B25;
      margin-bottom: 0;
    }

    p + p {
      margin-left: 3px;
      margin-top: 0;
    }
  }

  &__lower {
    display: flex;

    p {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #6F7277;
      margin-left: 4px;
      margin-bottom: 0;
    }
  }

  &__date {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    margin-bottom: 0;
    color: #9C9C9D;
  }
}

.activityInner {
  padding: 16px 16px 11px 16px;
  border-bottom: 1px solid #F7F8FA;

  &__sendDate {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #6F7277;
  }

  &__message {
    margin-top: 16px;
  }

  &__messageTitle {
    margin-bottom: 6px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #9C9C9D;
  }

  &__messageBody {
    background: rgba(240, 242, 245, 0.5);
    border-radius: 8px;
    padding: 10px;

    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.0075em;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.tabButtons {
  margin-top: 20px;
  margin-bottom: 12px;

  &__button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: #262626;
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 0;

    & + & {
      margin-left: 18px;
    }

    &_active {
      color: #00AAE6;
      font-weight: 500;
      border-bottom: 2px solid #00AAE6;
    }
    &_disabled{
      opacity: 0.5;
      cursor: inherit;
    }
  }
  &__activitiesModal{
    display: flex;
    margin-top: 8px;
    margin-bottom: 18px;
    border-bottom: 1px solid #EAECF0;
    position: relative;
  }
  &__activitiesModalButton {
    color: var(--accent, #667085);
    /* Text sm/Medium */
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;

    & + & {
      margin-left: 18px;
    }

    &_active {
      color: #1755E7;
      font-weight: 500;
      border-bottom: 2px solid #1755E7;
    }
  }
}
.editAddress{
  color: var(--accent, #0707FA);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
.addActivityBtn{
  border-radius: 8px;
  border: 1px solid #1755E7;
  background: #1755E7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white, #FFF);
  text-align: center;
  font-family: Inter, sans-serif;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  max-width: 113px;
  width: 100%;
  height: 32px;
  margin-right: 26px;
  cursor: pointer;
  &[disabled]{
    color: var(--white, #FFF);
  }
}
.popConfirm{
  position: absolute;
  background: rgba(0, 0, 0, 0.40);
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 32px 0;
  height: 100vh;
  &__content{
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width: 400px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 358px;
  }
  &__deleteTitle{
    color: var(--gray-900, #101828);
    text-align: center;
    /* Text lg/Semibold */
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    margin-top: 20px;
    margin-bottom: 8px;
  }
  &__deleteText{
    color: var(--gray-500, #667085);
    text-align: center;
    /* Text sm/Regular */
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 0;
  }
  &__item{
    width: 100%;
    label{
      color: var(--gray-700, #344054);
      /* Text sm/Medium */
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      margin-bottom: 6px;
    }
  }
  &__deleteBtns{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
  }
  &__warningIcon{
    border: 8px solid var(--warning-50, #FFFAEB);
    background: var(--warning-100, #FEF0C7);
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    border-radius: 50%;
  }
}
.deleteBtn{
  color: var(--accent, #D92D20);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}
.reqGridText{
  display: flex;
  align-items: center;
  min-height: 55vh;
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  justify-content: center;
}
.multipleOffer{
  &__header{
    display: flex;
    align-items: baseline;
  }
  &__reqText{
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 8px;
  }

}
.cancelRequest{
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--White, #FFF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 352px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.propertiesSearch{
  max-width: 352px;
  width: 100%;
  p{
    color: var(--Gray-900, #101828);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 15px 0 10px 0;
  }
  input{
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--White, #FFF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 32px;
    color: var(--Gray-500, #667085);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    &::placeholder{
      color: var(--Gray-500, #667085);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  button{
    border-radius: 8px;
    border: 1px solid var(--Accent, #1755E7);
    background: var(--Accent, #1755E7);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    margin-top: 24px;
    color: var(--White, #FFF);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    height: 40px;
  }
  &__notFound{
    max-width: 352px;
    width: 100%;

    input{
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--White, #FFF);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      height: 32px;
      color: var(--Gray-500, #667085);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      &::placeholder{
        color: var(--Gray-500, #667085);
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    button{
      &:first-child{
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--White, #FFF);
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        color: var(--Gray-700, #344054);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &:nth-child(2){
        border-radius: 8px;
        border: 1px solid var(--Accent, #1755E7);
        background: var(--Accent, #1755E7);
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        color: var(--White, #FFF);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
  &__notFoundTitle{
    color: var(--Gray-900, #101828);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 16px 0 4px 0;
  }
  &__notFoundText{
    color: var(--Gray-500, #667085);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    margin-top: 0;
  }
  &__cancelBtn{
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--White, #FFF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    height: 40px;
  }
  &__searchBtn{
    border-radius: 8px;
    border: 1px solid var(--Accent, #1755E7);
    background: var(--Accent, #1755E7);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    height: 40px;
  }
  &__buttons{
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: 24px;
  }
}
.shownSearchInput{
  max-width: 300px;
  width: 100%;
  height: 44px;
}
.searchInner{
  display: flex;
  gap: 16px;
  width: fit-content;
  position: relative;
  button{
    border-radius: 8px;
    border: 1px solid var(--secondary-sidebar, #1755E7);
    background: var(--secondary-sidebar, #1755E7);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: var(--White, #FFF);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100px;
    height: 44px;
  }
}
.mobileDivider{
  background: #F2F2F4;
  height: 2px;
  width: 80%;
  margin-left: 12px;
  border-top-left-radius: 12px;
}

.contactAddInfo{
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-wrap: wrap;
  &__name{
    color: var(--headline-h1, #161B25);
    display: block;
    min-width: fit-content;
    margin-right: 6px;
  }
  &__email{
    color: var(--Accent-in-grid, #1755E7);
    display: block;
    margin-right: 6px;
  }
  &__phone{
    color: var(--headline-h2, #6F7277);
    display: block;
    margin-right: 6px;
  }
}

@media (max-width: 870px) {
  .addActivityBtn{
    margin-right: 0;
  }
}
@media (max-width: 768px) {
    .filtersModal{
      border: none;
      box-shadow: none;
      right: 0;
      &__header{
        padding: 12px 24px;
        border-bottom: none;
        h3{
          color: var(--Ink-Darkest, #090A0A);
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin: 0;
        }
      }
      &__shownHidden {
        border-bottom: none;
        margin-bottom: 10px;
      }
    }
  .filtersSwitcherInner{
    margin-bottom: 12px;
  }
  .messageInner{
    margin-left: 0;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }
}
