.inner{
  padding: 0 0 26px 20px;
  &__header{
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
  }
}
.tableInner{
  padding: 20px 0 0 0;
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
}
.tableTitle{
  color: var(--Grey-Grey-90, #111827);
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin: 0 0 7px 20px;
}
.rowHeightSwitcher{
  position: absolute;
  right: -25px;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.rowCell{
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: #131313;
  font-size: 14px;
  line-height: 140%;
  padding: 0 10px;
}
//.qwe{
//  position: absolute;
//  top: -45px;
//  color: #000000;
//}
.search_input {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #9CA3AF;
  border: none;
  width: 270px;
  height: 32px;
  outline: none;
}
.rowsPerPage{
  height: 40px;
  margin: 0;
  border-top: none;
  border-bottom: none;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
}
.footerText{
  margin-bottom: 0;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px;
}
.selectBtn{
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.updateBtn{
  color: var(--accent, #0707FA);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
.addActivityBtn{
  border-radius: 8px;
  border: 1px solid #1755E7;
  background: #1755E7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white, #FFF);
  text-align: center;
  font-family: Inter, sans-serif;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  max-width: 113px;
  width: 100%;
  height: 40px;
  margin-right: 26px;
  cursor: pointer;
}
.tabButtons{
  display: flex;
  margin-top: 8px;
  margin-bottom: 18px;
  border-bottom: 1px solid #EAECF0;
  position: relative;
  &__button {
    color: var(--accent, #667085);
    /* Text sm/Medium */
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;

    & + & {
      margin-left: 18px;
    }

    &_active {
      color: #1755E7;
      font-weight: 500;
      border-bottom: 2px solid #1755E7;
    }
  }
}
.columnHeader {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #6B7280;
}
.filterButtons{
  margin-left: 20px;
}
.headerButtons{
  margin-right: 30px;
}
.mobileHeader{
  display: flex;
  justify-content: space-between;
}
.modalText{
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 253px;
  width: 100%;
  margin-top: -10px;
}

.switchButton{
  width: 32px;
  height: 32px;
  background-color: #2D2D2D;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 224px;
  z-index: 100;
}

.notFoundText{
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  max-width: 390px;
  width: 100%;
  margin-top: -15px;
}
.editReq{
  color: var(--Grey-Grey-90, #111827);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  outline: none;
  background-color: transparent;
}

@media (max-width: 480px) {
  .inner{
    padding: 0;
  }
}