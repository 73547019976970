.menu{
  min-width: 208px;
  padding: 40px 6px;
  position: relative;
  background-color: #FCFEFF;
  &__item{
    display: flex;
    padding: 8px 12px;
    border-radius: 6px;
    align-items: center;
    position: relative;
    cursor: pointer;

    & + &{
      margin-top: 4px;
    }
    a, p{
      cursor: pointer;
      color: #344054;
      font-size: 16px;
      margin-bottom: 0;
      font-family: Inter, sans-serif;
      font-weight: 500;
      line-height: 24px;
      margin-left: 12px;
    }
    &_active{
      background: #31394A;
      a, p{
        color: #FFF;
        cursor: pointer;
        margin-bottom: 0;
        font-size: 16px;
        font-family: Inter, sans-serif;
        font-weight: 600;
        line-height: 24px;
      }
    }
    &_arrow{
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    &_hovered{
      border-radius: 8px;
      display: flex;
      padding: 8px 12px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      position: absolute;
      z-index: 100;
      left: 61px;
      &_black{
        color: #FFFFFF;
        background: #31394A;
        &:before{
          content: '';
          position: absolute;
          top: 15px;
          left: -11px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 8px solid #31394A;
          transform: rotate(270deg);
        }
      }
      &_white{
        color: #31394A;
        background: #FFF;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        &:before{
          content: '';
          position: absolute;
          //box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
          top: 15px;
          left: -11px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 8px solid #FFF;
          transform: rotate(270deg);
        }
      }
      &_nested{
        color: #31394A;
        background: #FFF;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        width: 145px;
        a{
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-left: 0;
          display: flex;
          width: 100%;
          justify-content: center;
        }
        .menu__item{
          width: 100%;
          padding: 10px 12px;
          &:first-child{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:nth-child(2){
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
          a{
            color: #31394A;
          }
          &:hover{
            background-color: #31394A;
            a{
              color: #fff;
            }
          }
        }
        .menu__item + .menu__item{
          margin-top: 1px;
        }
        .menu__item_active{
          &:first-child{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:nth-child(2){
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
          a{
            color: #fff;
          }
        }
      }
    }
  }
}
.nestedMenu{
  display: flex;
  padding-left: 24px;
  margin: 8px 0;
  svg{
    margin-right: 8px;
  }
}
.hideButton{
  width: 40px;
  height: 24px;
  border-radius: 4px;
  background: #E2E2E2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  right: 5px;
}

.integrate_with_google {
  align-items: center;
  background: #FFF9DB;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  padding: 20px 22px;
  text-align: center;
  cursor: pointer;
  .banner_title {
    align-items: center;
    display: flex;

    span {
      color: #344054;
      font-size: 16px;
      font-weight: 500;
      margin-left: 10px;
    }

    svg {
      height: 24px;
      width: 24px;
      &.done_icon {
        align-self: start;
        margin-left: 5px;
        width: 10px;
      }
    }
  }

  .integration_description {
    color: #343A40;
    font-size: 12px;
    margin-top: 15px;
    line-height: 20px;
  }

  button {
    border-radius: 2px;
    background: #31394A;
    color: white;
    margin-top: 12px;
  }

  &.small_menu {
    cursor: pointer;
    padding: 12px;

    .banner_title span,
    .done_icon,
    .integration_description,
    button {
      display: none;
    }
  }

  &.integrated {
    background-color: transparent;
    border-top: 1px solid rgba(102, 112, 133, 0.60);
    border-radius: 0px;
    padding: 25px 0px 0px 0px;

    &.small_menu {
      cursor: pointer;
    }
  }
}
