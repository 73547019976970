.inner{
  position: absolute;
  z-index: 100;
  background-color: #FFFFFF;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 90px;
  &__header{
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 18px;
    margin-bottom: 18px;
    cursor: pointer;
  }
  &__headerTitle{
    margin-bottom: 0;
    color: var(--Ink-Darkest, #090A0A);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    width: 100%;
  }
}
.filtersTitle{
  color: var(--Ink-Darkest, #090A0A);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 34px 0 12px 24px;
}