@import '~antd/dist/antd.less';
@import './styleVars.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.app {
  background-color: #fff;
  color: var(--app-text-color);
  min-height: var(--app-min-height);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input::placeholder,
select::placeholder,
textarea::placeholder {
  color: rgba(102, 112, 133, 0.6) !important;
}
.pageWrapper {
  min-height: calc(100vh - 107px);
  padding: 24px 30px;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .pageWrapper {
    padding: 20px 15px;
  }
}

@media screen and (min-width: 300px) and (max-width: 479px) {
  .pageWrapper {
    padding: 20px 10px;
  }
}

@media screen and (min-width: 300px) and (max-width: 799px) {
  .app {
    min-height: 100vh;
  }
}
.svg-map .area-cover {
  fill: #fff;
  stroke: #ccc;
  stroke-width: 2;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  stroke-linejoin: round;
}

@primary-color: #1890FF;@input-height-base: 38px;@input-placeholder-color: #36374a80;@border-radius-base: 5px;@btn-height-base: 38px;@btn-font-weight: 600;@font-size-base: 16px;@form-item-label-font-size: 16px;@btn-default-ghost-color: @primary-color;@btn-default-ghost-border: @primary-color;@popover-padding-horizontal: 0px;@table-bg: transparent;@table-header-bg: transparent;