.inner{
  display: flex;
  margin-top: 63px;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  &__content{
    width: 100%;
    margin-right: 30px;
    position: relative;
  }
}
.tabButtons{
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #EAECF0;
  position: relative;
  &__button {
    min-width: 90px;
    color: var(--accent, #667085);
    /* Text sm/Medium */
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;

    & + & {
      margin-left: 15px;
    }

    &_active {
      color: #1755E7;
      font-weight: 500;
      border-bottom: 2px solid #1755E7;
    }
  }
}
.addButtons{
  border-radius: 8px;
  border: 1px solid #1755E7;
  background: #1755E7;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #FFF);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  height: 40px;
  margin-top: 13px;
}
.reqGridText{
  display: flex;
  align-items: center;
  min-height: 55vh;
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  justify-content: center;
}
.tableTitle{
  color: var(--grey-grey-90, #111827);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 11px;
  margin-top: 0;
}
.form__buttons{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.popConfirm{
  position: absolute;
  background: rgba(0, 0, 0, 0.40);
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 32px 0;
  height: 100vh;
  &__content{
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width: 400px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 358px;
  }
  &__deleteTitle{
    color: var(--gray-900, #101828);
    text-align: center;
    /* Text lg/Semibold */
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    margin-top: 20px;
    margin-bottom: 8px;
  }
  &__deleteText{
    color: var(--gray-500, #667085);
    text-align: center;
    /* Text sm/Regular */
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 0;
  }
  &__item{
    width: 100%;
    label{
      color: var(--gray-700, #344054);
      /* Text sm/Medium */
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      margin-bottom: 6px;
    }
  }
  &__deleteBtns{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
  }
  &__warningIcon{
    border: 8px solid var(--warning-50, #FFFAEB);
    background: var(--warning-100, #FEF0C7);
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    border-radius: 50%;
  }
  &__createContact{
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    border-radius: 50%;
    border: 8px solid var(--Primary-50, #F9F5FF);
    background: #F5F5FF;
  }
}
.badge{
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--gray-100, #F2F4F7);
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  right: 0;
}
.headerButtons{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.modeButtons{
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  width: fit-content;
  margin-bottom: 12px;
  &__button{
    min-width: 90px;
    cursor: pointer;
    color: var(--Gray-800, #1D2939);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    &_active{
      background: var(--Gray-50, #C3D2FA);
      font-weight: 500;
    }
  }
}
.searchInner{
  display: flex;
  gap: 16px;
  width: fit-content;
  position: relative;
  button{
    border-radius: 8px;
    border: 1px solid var(--secondary-sidebar, #1755E7);
    background: var(--secondary-sidebar, #1755E7);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: var(--White, #FFF);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100px;
    height: 44px;
  }
}
.shownSearchInput{
  max-width: 300px;
  width: 100%;
  height: 44px;
}